iframe {
  color-scheme: normal;
}

.formError {
  color: red;
  float: right;
}

.react-datepicker__time-list {
  margin-top: 0;
  margin-bottom: 0;
}

.react-datepicker__time-list li {
  margin-left: 0;
}

.chakra-breadcrumb__list {
  margin-top: 0;
  margin-bottom: 0;
}

.chakra-breadcrumb__list li {
  margin-left: 0;
}

.noSpacing {
  margin-top: 0;
  margin-bottom: 0;
}

.noSpacing li {
  margin-left: 0;
}

.itemDescription p {
  margin-bottom: 10px !important;
}

.chakra-ui-light pre {
  width: 100%;
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.chakra-ui-dark pre {
  width: 100%;
  background: #666;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #f4f4f4;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.chakra-ui-dark .blurred {
   text-shadow: 0 0 8px white;
   color: transparent;
}

.chakra-ui-light .blurred {
   text-shadow: 0 0 8px black;
   color: transparent;
}